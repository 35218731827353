import React, { useRef, useEffect } from "react"
import { renderImage } from "utils/imageRender"
import commaList from "utils/commaList"
import Link from "gatsby-plugin-transition-link"
import CardVideo from "components/card-video"

const Card = React.memo(({ card, className = "" }) => {
  const hasVideo = card.caseStudy.cardHasVideo
  const videoURL =
    hasVideo && card.caseStudy.cardVideo
      ? card.caseStudy.cardVideo.mediaItemUrl
      : null
  const observed = useRef(null)

  let state = {
    transitionType: "card",
    backgroundColour: card.caseStudy.hero.backgroundColour,
    card: null,
  }

  useEffect(() => {
    state.card = observed.current
  }, [observed])

  const termNames = card.terms.nodes.map(({ name }) => name)

  return (
    <div
      className={`case-card__container relative text-${card.caseStudy.textColour}`}
      ref={observed}
    >
      <Link
        className={`case-card bg-grey overflow-hidden block case-card${
          hasVideo ? "--has-video" : "--has-image"
        } ${className}`}
        to={`/work/${card.slug}/`}
        exit={{
          state,
          length: 2,
        }}
        entry={{
          length: 2,
          delay: 0.2,
        }}
      >
        <div className="case-card__media">
          <div className="case-card__media__content">
            <div className="relative">
              {renderImage(card.caseStudy.cardThumbnail)}
              {hasVideo && <CardVideo video={videoURL} />}
            </div>
          </div>
        </div>
      </Link>

      <div className="absolute inset-0 z-20 p-8 pointer-events-none sm:p-10">
        <h2 className="mb-3 font-serif text-llama">{card.title}</h2>
        <div className="case-study__subtitle">
          <span className="case-study__services">{commaList(termNames)}</span>
          <span className="case-study__link-text">Explore project</span>
        </div>
      </div>
    </div>
  )
})

export default Card

import React from "react"
import Card from "components/work/card"
import FadeIn from "transitions/fade-in"

const Grid = props => {
  const { cards, otherCaseStudies } = props

  return (
    <section className="mt-16 sm:mt-24 mb-24 o-container sm:my-30">
      <div className="grid gap-4 md:row-gap-10 md:col-gap-4 xs:grid-cols-2 md:grid-cols-3">
        {cards &&
          cards.map((card, i) => (
            <FadeIn key={`card-${card.databaseId + i}`} delay={`${i * 50}`}>
              <Card card={card} />
            </FadeIn>
          ))}
      </div>
      <div className="md:px-7">
        <div className="grid gap-12 my-20 xs:grid-cols-2 md:grid-cols-3 lg:row-gap-18 md:my-36">
          {otherCaseStudies &&
            otherCaseStudies.map((block, i) => (
              <FadeIn
                key={`small-card-${block.databaseId}`}
                delay={`${i * 50}`}
              >
                <div className="text-center xs:text-left">
                  <h2 className="mb-5 font-serif text-2xl">{block.title}</h2>
                  <div
                    className="style-links"
                    dangerouslySetInnerHTML={{
                      __html: block.caseStudy.thumbnailDescription,
                    }}
                  ></div>
                </div>
              </FadeIn>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Grid

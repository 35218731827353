import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import FadeIn from "transitions/fade-in"

const Categories = ({ cat, setCat }) => {
  let {
    allWpServiceCategory: { categories },
  } = useStaticQuery(graphql`
    query ServiceCategories {
      allWpServiceCategory {
        categories: nodes {
          databaseId
          slug
          name
          description
          serviceCategory {
            order
          }
        }
      }
    }
  `)

  categories
    .sort((a, b) =>
      a.serviceCategory.order > b.serviceCategory.order ? 1 : -1
    ) // Order by custom field
    .forEach(c => {
      // Add full URL to each
      c.url = c.slug === "all" ? `/work/` : `/work/category/${c.slug}/`
    })

  // Prepend the "All" category
  categories = [
    {
      databaseId: "all",
      name: "All",
      slug: "all",
      url: "/work/",
    },
    ...categories,
  ]

  return (
    <section className="mb-16 text-center md:px-7 categories mt-22 sm:my-26 md:mt-36 xl:mt-20">
      <div className="container">
        <FadeIn>
          <ul className="flex flex-col flex-wrap items-start justify-center font-serif sm:mt-5 sm:flex-row text-iron text-38px style-links">
            {categories.map(({ name, description, databaseId }) => (
              <button
                onClick={() => setCat(name !== "All" ? name : "")}
                className={`${(name !== "All" ? name : "") === cat &&
                  "text-midnight is-active"} my-6 sm:mx-6 text-iron tg-underline--large block sm:inline-block`}
                key={`category-${databaseId}`}
              >
                {description || name}
              </button>
            ))}
          </ul>
        </FadeIn>
      </div>
    </section>
  )
}

export default Categories

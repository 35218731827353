import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import Categories from "components/work/categories"
import Grid from "components/work/grid"
import ArticlesMarquee from "components/flexible/articles-marquee"

const Work = props => {
  const {
    data: { wpPage },
    pageContext,
  } = props

  if (!wpPage) return null

  const { seo } = wpPage

  const [cat, setCat] = useState(
    pageContext.cat && pageContext.cat.slug ? pageContext.cat.slug : ""
  )
  const { caseStudyCards, otherCaseStudies } = wpPage.work

  const [cards, setCards] = useState(caseStudyCards)

  useEffect(() => {
    const selectedCards = cat.length
      ? caseStudyCards.filter(rs => {
          const termNames = rs.terms.nodes.map(({ name }) => name)
          return termNames.includes(cat)
        })
      : caseStudyCards
    return setCards(selectedCards)
  }, [cat])

  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--work-listing",
        }}
      />
      <Categories cat={cat} setCat={setCat} />
      <Grid cat={cat} cards={cards} otherCaseStudies={otherCaseStudies} />
      <ArticlesMarquee {...wpPage.work.articlesMarqueeWork} />
    </Transition>
  )
}

export const query = graphql`
  query Work {
    wpPage(databaseId: { eq: 3835 }) {
      ...SEO
      work {
        caseStudyCards {
          ...CaseStudyCard
        }
        otherCaseStudies {
          ... on WpCaseStudy {
            title
            databaseId
            caseStudy {
              thumbnailDescription
            }
          }
        }
        articlesMarqueeWork {
          heading
          content
          items {
            ... on WpExternalArticle {
              ...ExternalArticle
            }
          }
        }
      }
    }
  }
`

export default Work
